
import { computed, defineAsyncComponent, defineComponent, onMounted, readonly, ref, unref, watch } from 'vue'
import NavbarLogo from '@/components/dashboard/navbar/NavbarLogo.vue'
import WizardSteps from '@/components/wizard/WizardSteps.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import BaseInput from '@/components/base/BaseInput.vue'
import MultiSelect from '@vueform/multiselect'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import languageSelect from '@/components/settings/languageSelect.vue'
import { useToast } from '@/composables/useToast'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import InstallmentOptions from '@/components/pages/checkout/InstallmentOptions.vue'
import CouponOptions from '@/components/pages/checkout/CouponOptions.vue'
import useVuelidate from '@vuelidate/core'
import { useToggle } from '@/composables/useToggle'
import { fbPixel } from '@/plugins/facebook/pixel'
import TotalPayable from '@/components/pages/checkout/TotalPayable.vue'
import { currentUser } from '@/composables/user/currentUser'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  components: { BaseModal, NavbarLogo, WizardSteps, BaseInput, MultiSelect, languageSelect, PurchaseSummary, InstallmentOptions, CouponOptions, TotalPayable },
  setup () {
    const profiles = ref<any[]>()
    const selectedProfileId = ref<string | null>(null)
    
    const router = useRouter()

    const { user } = currentUser()
    
    const { t } = useI18n()

    const { format } = useNumeral()

    const {
      isLoaded,
      installments,
      products,
      total,
      paymentFlags,
      checkoutFormData,
      submitCheckoutStatus,
      checkoutCode,
      useMonthlyValue,
      fetchCheckoutInfo,
      submitCheckoutData,
      saveLead,
      researchUserProfiles,
      updateCheckoutFormData,
      isInternational,
    } = useCheckout()

    const {
      isActive: isSending,
      setActive,
      setInactive
    } = useToggle(false)

    const {
      isActive: isModalOpen,
      // setActive: openModal,
      setInactive: closeModal
    } = useToggle(false)

    const flags: {[key: string]: string} = readonly({
      'visa': 'fab fa-cc-visa',
      'mastercard': 'fab fa-cc-mastercard',
      'american-express': 'fab fa-cc-amex',
      'discover': 'fab fa-cc-discover',
      'diners-club': 'fab fa-cc-diners-club',
      'jcb': 'fab fa-cc-jcb',
      'boleto': 'fas fa-barcode'
    })
    
    const currentIndex = ref(0)

    const tabs = computed(() => {
      return [
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/ContactCheckout.vue')),
          title: t('page.checkout.sentence_contact'),
          rules: {},
          visible: true
        },
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/AddressCheckout.vue')),
          title: t('page.checkout.sentence_address'),
          rules: {},
          visible: !isInternational.value
        },
        {
          component: defineAsyncComponent(() => import('@/components/pages/checkout/PaymentCheckout.vue')),
          title: t('page.checkout.sentence_payment'),
          rules: {},
          visible: true
        }
      ].filter((tab) => tab.visible)
    })

    const steps = computed(() => {
      return tabs.value.map((tabs, index) => ({
          title: tabs.title,
          key: `step-${index}`,
          index
        }))
    })

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index
    }

    const currentTab = computed(() => {
      return tabs.value[unref(currentIndex)]
    })

    const currentComponent = computed(() => {
      return unref(currentTab).component
    })

    const isFirstStep = computed(() => unref(currentIndex) === 0)

    const isLastStep = computed(() => unref(currentIndex) === steps.value.length - 1)

    const previousTab = async () => {
      if (unref(currentIndex) > 0) {
        currentIndex.value--
      }
    }

    const v$ = useVuelidate()

    const isFormValid = () => {
      v$.value.$touch()
      v$.value.$validate()
      return !v$.value.$invalid
    }

    const nextTab = () => {
      if (currentIndex.value < steps.value.length - 1) {
        if (!isFormValid()) {
          // const { $property, $validator } = v$.value.$errors[0]
          // console.log($property, $validator)
          return
        }
        
        currentIndex.value++
      }
    }

    const handleUserProfilesList = async () => {
      profiles.value = await researchUserProfiles()
    }

    watch(currentIndex, async (after, before) => {
      if (before === 0 && after === 1) {
        saveLead()
        await handleUserProfilesList()
      }
    })

    const finishWizard = async () => {
      const getProfilesResume = () => {
        const resume = {
          profilesCount: profiles.value?.length || 0,
          activeProfilesCount: 0,
          inactiveProfilesCount: 0,
        }

        resume.inactiveProfilesCount = (profiles.value?.filter(profile => profile._active === false))?.length || 0
        resume.activeProfilesCount = (profiles.value?.filter(profile => profile._active === true))?.length || 0

        return resume
      }


      if (isFormValid()) {
        if (profiles.value && profiles.value.length) {
          const resume = getProfilesResume()
  
          if (resume.profilesCount === 1 && resume.profilesCount === resume.inactiveProfilesCount) {
            selectedProfileId.value = profiles.value?.[0].id
          }

          // if (!selectedProfileId.value) {
          //   openModal()
          //   return
          // }
        }

        handleSubmitOrder(selectedProfileId.value || '')
      }
    }

    const handleSelectProfile = async () => {
      handleSubmitOrder(selectedProfileId.value || '')
    }

    const handleSubmitOrder = async (profileId?: string) => {
      setActive()
        
      await submitCheckoutData(profileId)
      
      if (submitCheckoutStatus.value.success) {
        useToast.fire({
          icon: 'success',
          title: t('state.checkout_success.title'),
          text: t('state.checkout_success.message')
        })
        router.replace({ name: 'thanks' })
      } else {
        useToast.fire({
          icon: 'error',
          title: 'Ops',
          text: submitCheckoutStatus.value.error
        })
        setInactive()
      }
    }

    const getValidPhoneNumber = (input: string) => {
      let phone = input.replace(/[^0-9]/g,'')
      return phone
      // phone = phone.substring(phone.length - 11)
      // if (phone.length < 11) {
      //   return null
      // }
      // const [areaCode, phoneNumber] = [phone.slice(0, 2), phone.slice(2)]
      // return `(${areaCode}) ${phoneNumber}`
    }

    const getQueryStringData = () => {
      const { email, name, phone, profile } = router.currentRoute.value.query

      const payload = { 
        ...(email && {email}),
        ...(name && {name}),
        ...(phone && {phone: getValidPhoneNumber(phone as string)})
      }

      updateCheckoutFormData(payload)

      if (profile) {
        selectedProfileId.value = profile as string
      }
    }

    const getLoggedCustomerInfo = () => {
      let phoneNumber
      if (unref(user).billing?.customer?.phone) {
        phoneNumber = getValidPhoneNumber(unref(user).billing?.customer?.phone)
      }


      if (unref(user).id) {
        const payload = { 
          ...(unref(user).email && {email: unref(user).email}),
          ...(unref(user).name && {name: unref(user).name}),
          ...(phoneNumber && {phone: phoneNumber})
        }
  
        if (Object.keys(payload).length > 0) {
          updateCheckoutFormData(payload)
        }
      }
    }

    onMounted( () => {
      fetchCheckoutInfo(checkoutCode.value)
      fbPixel('track', 'PageView')
      fbPixel('track', 'InitiateCheckout')
      getQueryStringData()
      getLoggedCustomerInfo()
    })

    return {
      isLoaded,
      format,
      currentIndex,
      tabs,
      steps,
      setCurrentIndex,
      currentComponent,
      isFirstStep,
      isLastStep,
      previousTab,
      nextTab,
      finishWizard,
      installments,
      products,
      total,
      paymentFlags,
      flags,
      checkoutFormData,
      useMonthlyValue,
      isSending,
      isModalOpen,
      closeModal,
      profiles,
      selectedProfileId,
      handleSelectProfile
    }
  }
})
